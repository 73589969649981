/*================================================================================
	Item Name: Vuexy - Vuejs, HTML & Laravel Admin Dashboard Template
	Author: PIXINVENT
	Author URL: http://www.themeforest.net/user/pixinvent
================================================================================

NOTE:
------
PLACE HERE YOUR OWN SCSS CODES AND IF NEEDED, OVERRIDE THE STYLES FROM THE OTHER STYLESHEETS.
WE WILL RELEASE FUTURE UPDATES SO IN ORDER TO NOT OVERWRITE YOUR STYLES IT'S BETTER LIKE THIS.  */

@import url('https://fonts.googleapis.com/css2?family=Inter:wght@100;300;400;500;700;800&display=swap');

body {
  font-family: 'Inter', sans-serif;
}

.navigation, .header-navbar {
  font-family: 'Inter', sans-serif;
}

.brand-text{
  font-weight: 400 !important;
}

.main-menu.menu-light .navigation > li.active > a {
  box-shadow: none;
}

.navigation-header {
  margin-left:1.2rem !important;
}

.popover-body {
  white-space: nowrap;
  background-color: #fff !important;
  color: #000 !important;
  padding: 0.5rem 0.7rem !important;
  font-size: 13px;
}

.popover > .arrow::after {
  border-top-color: #fff !important;
}

.navigation-text {
  cursor: pointer;
  text-decoration: underline;
}

.navigation-text:hover {
  text-decoration:none;
}

.loading-container {
  width: 100%;
  height: 400px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.more-button {
  background: none;
  color: inherit;
  border: none;
  cursor: pointer;
  outline: inherit;
}

.popover-action {
  padding: 0.75rem;
  background-color: #ffffff;
  cursor: pointer;
  min-width: 150px;
  display: flex;
  flex-direction: row;
  gap: 0.75rem;
  align-items: center;
}
.popover-action:hover {
  background-color: #f6f6f6;
}

.popover-action-icon {
  min-width: 20px;
  i {
    font-size: 1.25rem;
    vertical-align: middle;
  }
}

.popover-action-text {
  font-size: 1rem;
}

.table-more-col {
  width: 100px;
}


.svg-funnel-js__container{
  margin-top: 3vh !important;
  margin-bottom: 3vh !important;
}

.wrapper-accordion-border {
  .card {
    border: 1px solid #ebe9f1 !important;
    box-shadow: none !important;
    .card-header button {
      text-align: left;
    }
    .card-body {
      padding: 0 1.25rem 1.25rem !important;
    }
  }
}

.max-w700px {
  max-width: 700px !important;
}

.w-10 {
  width: 10% !important;
}

.w-15 {
  width: 15% !important;
}

.w-20 {
  width: 20% !important;
}

.sort-icon {
  cursor: pointer;
}

.main-menu .shadow-bottom {
  display: none !important;
}

.main-menu .navbar-header .navbar-brand {
  margin-top: 0.35rem !important;
}

.table td {
  padding: 1.25rem 2rem;
}

.element-blur-filter {
  filter: blur(3px);
  pointer-events: none;
  cursor: default;
  webkit-user-select: none;
  -ms-user-select: none;
  user-select: none;
}

.form-group-custom {
  display: flex !important;
  flex-direction: row !important;
  gap: 1rem;
  // align-items: center;
  max-width: 700px;
  min-height: 36px;
  & > label {
    text-align: right;
    min-width: 150px;
    margin: 0.75rem 0 0 !important;
  }
  ng-select, ng2-flatpickr, .wrapper-input-custom, .form-control {
    width: 100%;
  }
  .input-group {
    flex-wrap: inherit;
  }
  .wrapper-input-custom {
    position: relative;
  }
  .textarea-counter-value {
    position: absolute;
    right: 20px;
    margin: 0;
  }
}

.btn-outline-secondary {
  border-color: #ccc !important;
}

.wrapper-btn-sm {
  .btn {
    padding: 0.486rem 1rem;
    font-size: 0.9rem;
    line-height: 1;
    border-radius: 0.358rem;
  }
}

.wrapper-btn-large {
  .btn {
    padding: 0.8rem 2rem;
    font-size: 1.25rem;
    line-height: 1.25;
    border-radius: 0.358rem;
    min-width: 150px;
  }
}

.more-button {
  .feather {
    height: 18px;
    width: 18px;
  }
}

.gap-25 {
  gap: 0.25rem;
}

.gap-50 {
  gap: 0.50rem;
}

.gap-75 {
  gap: 0.75rem;
}

.gap-1 {
  gap: 1rem;
}

.row-gap-0 {
  row-gap: 0rem;
}

.row-gap-50 {
  row-gap: 0.50rem;
}

.row-gap-75 {
  row-gap: 0.75rem;
}

.row-gap-1 {
  row-gap: 1rem;
}

.ng-select.ng-select-multiple .ng-select-container .ng-value-container .ng-placeholder {
  top: 50% !important;
  transform: translateY(-50%);
  padding-bottom: 0 !important;
}

.ng-select.ng-select-focused:not(.ng-select-opened)>.ng-select-container {
  border-color: #000000;
}

.form-container .options-container .form-group.form-group-custom {
  margin-bottom: 0 !important;
}
.form-container .options-container .form-group.form-group-custom > label {
  display: none !important;
}

input[type=radio], input[type=checkbox] {
  width: 18px;
  height: 18px;
}

.wrapper-linx-checkbox-middle {
  .form-group-custom {
    align-items: center !important;
    .custom-checkbox {
      label {
        margin-bottom: 0;
      }
    }
  }
}

.bs-popover-bottom > .arrow, .bs-popover-auto[x-placement^=bottom] > .arrow {
  top: -0.45rem;
}

.bs-popover-bottom > .arrow::before, .bs-popover-auto[x-placement^=bottom] > .arrow::before {
  border-bottom-color: #555;
}

.popover.bs-popover-bottom .arrow:after, .popover.bs-popover-auto[x-placement^=bottom] .arrow:after {
  border-bottom-color: #ffffff;
}

@media screen and (max-width: 767px) {
  .form-group-custom {
    flex-direction: column !important;
    align-items: flex-start !important;
    gap: 0.5rem;
    & > label {
      text-align: left;
      min-width: 150px;
      margin: 0 !important;
    }
    &.mobile-d-none {
      display: none !important;
    }
  }
  .wrapper-linx-checkbox-middle .form-group-custom {
    align-items: flex-start !important;
    justify-content: center !important;
  }
}

.form-wrapper {
  .form-container {
    .form-body {
      .template-container {
        max-width: 100%;
      }
    }
  }
}

.object-fit-cover {
  object-fit: cover;
}
